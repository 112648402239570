import { graphql } from 'gatsby'
import React from 'react'

import { NewsSectionLanding } from '../../components/NewsSectionLanding'

type AcademyCupPageProps = {
  data: any
}

export default function AcademyCupPage({ data }: AcademyCupPageProps) {
  return (
    <NewsSectionLanding
      featuredImage={data.cover?.childImageSharp?.fluid}
      newsCategory={'academyCup'}
      title={'Academy Cup'}
    />
  )
}

export const query = graphql`
  query AcademyCupPage {
    cover: file(name: { eq: "230922_Locandina-Definitva-PR-Academy-Cup_2" }) {
      childImageSharp {
        fluid(maxWidth: 2160, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
