import React from 'react'

import GatsbyImage from 'gatsby-image'
import { Col, Row } from 'react-bootstrap'

import { NewsCategory } from '../../actions/articles'

import { Blanket, BlanketContent, BlanketTitle } from '../Blanket'
import { Container } from '../Container'
import { NewsFeed } from '../NewsFeed'
import { SEO } from '../Seo'

type NewsSectionLandingProps = {
  content?: React.ReactNode
  featuredImage: any
  newsCategory: NewsCategory
  title: string
}

export function NewsSectionLanding({
  content,
  featuredImage,
  newsCategory,
  title,
}: NewsSectionLandingProps) {
  return (
    <Blanket dark={false} noPadding>
      <SEO title={title} />

      <Container>
        <BlanketTitle>{title}</BlanketTitle>

        {content && <BlanketContent>{content}</BlanketContent>}

        <BlanketContent>
          <Row>
            <Col xs={12} md={6}>
              <GatsbyImage fluid={featuredImage} />
            </Col>

            <Col xs={12} md={6}>
              <NewsFeed category={newsCategory} />
            </Col>
          </Row>
        </BlanketContent>
      </Container>
    </Blanket>
  )
}
